@import '../../styles/variables';

.switch-menu-modal {
  min-width: 375px;
  max-width: 90vw;
  .p-dialog-content {
    padding: var(--spacing-unit);
  }
  .p-dialog-header {
    padding: var(--spacing-unit) var(--spacing-unit) 0 var(--spacing-unit);
  }
  .p-dialog-footer {
    padding: 0 var(--spacing-unit) var(--spacing-unit) var(--spacing-unit);
    .btn {
      width: 100%;
    }
  }
}

.product-description-old {
  padding-bottom: calc(var(--spacing-unit) * 4);
  flex-direction: row-reverse;
  .col-2 {
    width: 50%;
  }
  .image-wrapper {
    position: relative;
    height: 548px;
    .carousel {
      height: 100%;
      .carousel-el {
        background-color: var(--white);
      }
    }
    .carousel-details {
      height: 92%;
    }
  }
  .description p {
    font-size: inherit;
    line-height: inherit;
  }
  .right-col {
    padding-left: calc(var(--spacing-unit) * 4);
  }
  .summary {
    position: sticky;
    top: 100px;
    z-index: 99;
    .variant-radio-form {
      width: 100%;
      .variant-option {
        position: relative;
        .selected-icon {
          position: absolute;
          right: -7px;
          top: -7px;
        }
        input {
          position: absolute;
          opacity: 0;
          &:focus + label {
            outline: 4px solid rgba(0, 125, 250, 0.6);
            outline-offset: 1px;
          }
        }
        label {
          display: flex;
          flex-direction: column;
          font-family: var(--font);
          cursor: pointer;
          border: 1px solid var(--secondary-light);
          background-color: var(--white);
          padding: 12px 20px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          min-width: 88px;
          max-width: fit-content;
          width: 100%;
          box-shadow: rgb(0 0 0 / 10%) 0px 4px 10px;
          border-radius: 4px;
          &:hover {
            border: 1px solid var(--primary);
            color: var(--input-normal);
          }
          &.selected,
          &.selected:hover {
            border: 2px solid var(--primary);
            background-color: var(--white);
          }
          &[data-has-variant='true'] {
            height: 80px;
            .price {
              margin-top: 3px;
            }
          }
        }
        &:last-child {
          label {
            margin-right: 0;
          }
        }
      }
    }
    .cart-button-col {
      flex-grow: 1;
    }
  }
  @include media-breakpoint-down(sm) {
    flex-direction: column;
    padding-bottom: 0;
    &:last-of-type {
      padding-bottom: calc(var(--spacing-unit) * 3);
    }
    .image-wrapper {
      height: 343px;
    }
    .col-2 {
      width: 100%;
    }
    .summary {
      position: static;
    }
  }
}

.sticky-cart-buttons {
  height: 80px;
  padding: var(--spacing-unit) 10px;
  z-index: 101;
  background-color: var(--white);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: 0 -3px 4px rgba(0, 0, 0, 0.1);
  .quantity-select {
    margin-right: var(--spacing-unit);
  }
}

.pdp-cart-buttons {
  padding: 0 var(--spacing-unit);
  .quantity-select {
    margin-right: var(--spacing-unit);
  }
}

.pdp-lower > div > section:first-of-type {
  .products-carousel-container {
    padding-top: calc(var(--spacing-unit) * 4);
  }
}

.product-description-old.pdp-loader {
  .product-image {
    height: 100%;
    @include media-breakpoint-down(sm) {
      height: 500px;
    }
    @include media-breakpoint-down(xs) {
      height: 200px;
    }
  }
  .summary > .description p {
    margin-bottom: 5px;
  }
  .cart-container {
    > span:first-of-type {
      @include media-breakpoint-up(md) {
        margin-right: var(--spacing-unit);
      }
    }
  }
}

#moodi-day-widget {
  .moodi-day-video-review-header {
    margin-bottom: 0;
    h2 {
      color: var(--primary);
      .moodi-day-info-icon {
        margin: 5px 0 0 5px;
      }
    }
  }
  .slick-slider .slick-track {
    width: 100% !important;
    overflow-x: auto;
  }
  .slick-slider .slick-slide {
    min-width: 220px;
    width: 100% !important;
  }
  @include media-breakpoint-down(sm) {
    .moodi-day-video-review-module {
      padding: 24px var(--spacing-unit);
    }
  }
}
